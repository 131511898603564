import routes from '@helpers/routes';

export const isProtected = (route) => {
    let unprotectedRoutes = [
        '/',
        '/adminLogin',
        '/faq',
        '/terms-and-condition',
        '/risk-disclosure',
        '/disclaimer',
        '/privacy-policy',
        '/corporate-bond',
        '/contact-us',
        '/about-us',
        '/app/signup',
        '/login',
        '/create-new-account',
        routes.bonds.base,
        '/media',
        '/bondfundamentals',
        '/glossary',
        '/credit-evaluation-process',
        '/investor-charter',
        '/newsletters',
        '/webinars',
        routes.loggedOutOpportunities,
        ...(route.includes(routes.bonds.bondsIsin) ? [route] : []),
        ...(route.includes('/invitations') ? [route] : []),
        ...(route.includes('/blogs') ? [route] : []),
        ...(route.includes('/products') ? [route] : []),
        ...(route.includes('/calculators') ? [route] : []),
    ];

    return !unprotectedRoutes.includes(route);
};
